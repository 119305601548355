<template>
  <main>
    <v-main>
      <v-app light>
        <core-toolbar />
        <core-drawer />
        <v-fade-transition mode="out-in">
          <router-view class="grey lighten-5" />
        </v-fade-transition>
        <core-footer v-if="displayFooter()" />
      </v-app>
    </v-main>
  </main>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    commiters: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data: () => ({
    listCommiters: [],
  }),
  mounted() {
    this.$vuetify.theme.dark = false;
  },
  created() {
    this.listCommiters.push(...(this.commiters || []));
    this.init({
      commiters: this.commiters || [],
    });
  },
  watch: {
    commiters(val) {
      if (this.listCommiters.filter(x => val.includes(x)).length !== val.length) {
        this.listCommiters.push(...val);
        this.$store.commit('config/set_loaded', false);
        this.init({
          commiters: this.listCommiters,
        });
      }
    },
  },


  methods: {
    ...mapActions('config', ['init']),
    displayFooter() {
      return !this.$route.path.includes('/pim') && !this.$route.path.includes('/settings');
    },
  },
};
</script>

<style></style>
